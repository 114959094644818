import React from "react"
import { Card } from "react-bootstrap/Card"
import Link from "gatsby"
import { speaker, indianSpeaker } from "../data/indo-russia/indo-russia-data"
import earth from "../images/newDesign/earth.jpg"
export default function IndoRusssiaRoundTable() {
  return (
    <>
      <div className="custom-container">
        <div className="">
          <div className="row px-3 px-sm-3 px-md-5 px-lg-5 px-xl-5 mt-5 align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-5 order-last order-sm-last order-md-last order-lg-first order-xl-first">
              <h1 className="heading ">
                India — Russia: Cooperation for Sustainable Development
              </h1>
              <div className="row text-center px-2">
                <div
                  className="col-md-4 border-end comman-para"
                  style={{ background: "#5b0017c4", color: "#fff" }}
                >
                  Friday, June 9th, 2023
                </div>
                <div
                  className="col-md-4 border-end comman-para"
                  style={{ background: "#5b0017c4", color: "#fff" }}
                >
                  Time (IST) : 04:30 pm - 06:00 pm
                </div>
                <div
                  className="col-md-4 comman-para"
                  style={{ background: "#5b0017c4", color: "#fff" }}
                >
                  Duration : 90 Minutes
                  {/* <h4 className="heading text-white">
                    <a
                      className="text-white"
                      href="https://esgcongress.com/en/20230606_video"
                      target="_blank"
                    >
                      CLICK HERE TO JOIN
                    </a>
                  </h4> */}
                </div>
              </div>
              <h4 className="fw-bold mt-2 pt-5">
                Round table 'Business-to-Business Cooperation on ESG Matters.
                Sustainable Development Agenda Priorities for Russian and Indian
                Businesses'
              </h4>
              <a
                style={{
                  background: "#b05821",
                  color: "#FFF",
                  border: "1px solid #FFF",
                }}
                className="btn learn-more-btn mt-5"
                href="/indo-russia-registration-form"
              >
                <h4 className="">INDIA-RUSSIA ROUND TABLE REGISTRATION FORM</h4>
              </a>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 pt-5 order-first order-sm-first order-md-first order-lg-last order-xl-last">
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/2TLGriFB02c"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <h1 className="heading mb-5 mt-5"> </h1>

          <div className="mt-5 pt-3 px-3 px-sm-3 px-md-5 px-lg-5 px-xl-5 ">
            <div className="row comman-para mt-5">
              <div className="col-md-3"></div>
              <div className="col-md-9 russia-adenda">
                <h1 className="heading">AGENDA</h1>
              </div>
            </div>
            <div className="row comman-para">
              <h4 className="col-md-3 russia-heading">Round table 1</h4>
              <div className="col-md-9 russia-adenda">
                <ul>
                  <li>
                    Inaugural Note - Vikrant Abrol, Founder, Indian ESG Network
                  </li>
                  <li>
                    Opening Remarks - Mikhail Glazachev, Counsellor Finance,
                    Russian Embassy and Representative of the Ministry of
                    Finance , Russian Federation
                  </li>
                  <li>
                    Introductions of all Participants - Ekaterina
                    Salugina-Sorokovaya and CA. Puneeta Puri
                  </li>
                  <li>
                    Opening Remarks and Setting the Tone for Round Table - Chair
                    Dr. Anjal Prakash, Associate Professor Research & Research
                    Director, Bharti Institute of Public Policy, ISB
                  </li>
                </ul>
              </div>
            </div>
            <div className="row comman-para mt-5">
              <div className="col-md-3"></div>
              <div className="col-md-9 russia-adenda">
                Key Discussion Points (Each 5-7 min)
              </div>
            </div>
            <div className="row comman-para">
              <h4 className="col-md-3 russia-heading">
                Session 1 - Journey of Companies towards Sustainability (30 min)
              </h4>
              <div className="col-md-9 russia-adenda">
                <ul>
                  <li>
                    How have the companies progressed towards sustainability.
                  </li>
                  <li>
                    What strategies and initiatives have been implemented to
                    achieve sustainability goals.
                  </li>
                  <li>
                    Challenges in achieving sustainability within an
                    organisation.
                  </li>
                  <li>
                    Are there any sector-specific challenges faced while
                    building a sustainable business.
                  </li>
                  <li>
                    How do we see the future of sustainability in Indian and
                    Russian companies
                  </li>
                </ul>
              </div>
            </div>
            <div className="row comman-para mt-5">
              <div className="col-md-3"></div>
              <div className="col-md-9 russia-adenda">
                <div className="mb-3">
                  <span
                    className=""
                    style={{
                      background: "#5B0017",
                      color: "#fff",
                      padding: "5px 4%",
                      borderRadius: "20px",
                    }}
                  >
                    BREAK : 5 Minutes
                  </span>
                </div>

                <div>Key Discussion Points (Each 5-7 min)</div>
              </div>
            </div>

            <div className="row comman-para">
              <h4 className="col-md-3 russia-heading">
                Session 2 - Sustainability solutions, learnings and best
                practices. Key regulatory and reporting requirements (40 min)
              </h4>

              <div className="col-md-9 russia-adenda">
                <ul>
                  <li>
                    What are some key lessons learned from sustainability
                    initiatives which were implemented.
                  </li>
                  <li>
                    Sustainable development topics and regulations for Russian
                    and Indian businesses.
                  </li>
                  <li>
                    What sustainable development topics and regulations should
                    Russian and Indian businesses focus on when working
                    together.
                  </li>
                  <li>
                    Key challenges and learnings from implementation of
                    reporting structures and compliances.
                  </li>
                  <li>
                    Is environmental sustainability and biodiversity a primary
                    concern for businesses or regulators.
                  </li>
                  <li>
                    How can businesses contribute to environmental
                    sustainability and biodiversity conservation.
                  </li>
                </ul>
              </div>
            </div>

            <div className="row comman-para mt-5">
              <h4 className="col-md-3 russia-heading">Closing Remarks</h4>
              <div className="col-md-9 russia-adenda">
                Moderator and Closing Remarks - Evgeniy Khilinskiy (GAZPROMBANK)
              </div>
            </div>
          </div>

          <div className="mt-5 pt-5 px-3 px-sm-3 px-md-5 px-lg-5 px-xl-5 ">
            <h1 className="heading">SPEAKERS</h1>
            <div className="row">
              {speaker.map(data => {
                return (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
                    <div className="card h-100 custom-card border common-card">
                      <img
                        src={data.pictureUrl}
                        class="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h4 className="common-second-heading">
                              {data.name}
                            </h4>
                            <h4 className="">{data.designation}</h4>
                            <p className=" pt-2">{data.details}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
