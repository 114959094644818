import ElenaMyakotnikova from "../../images/newDesign/carousalImages/ElenaMyakotnikova.jpg"
import NikitaVorobyev from "../../images/newDesign/carousalImages/NikitaVorobyev.jpg"
import EvgeniyKhilinskiy from "../../images/newDesign/carousalImages/EvgeniyKhilinskiy.jpg"
import OlgaKalashnikova from "../../images/newDesign/carousalImages/OlgaKalashnikova.jpg"
import IrinaBahtina from "../../images/newDesign/carousalImages/IrinaBahtina.jpg"
import AnjalPrakash from "../../images/newDesign/carousalImages/DrAnjalPrakashhighres.png"
import Default from "../../images/newDesign/carousalImages/Default.png"
import DineshAgrawal from "../../images/newDesign/carousalImages/2.jpg"
import SuchinJain from "../../images/newDesign/carousalImages/3.png"
import SanjayGupta from "../../images/newDesign/carousalImages/9.jpg"
import KristinaPopilyuk from "../../images/newDesign/carousalImages/30.jpg"
import ArindamLahiri from "../../images/newDesign/carousalImages/arindam_lahiri.png"
import ShrutiGanguli from "../../images/newDesign/carousalImages/Shrutee.png"

export const speaker = [
  {
    pictureUrl: ElenaMyakotnikova,
    name: "Elena Myakotnikova",
    designation: "Head of climate and carbon regulation, SIBUR",
    details:
      "SIBUR is one of the world's fastest-growing petrochemical players and Russia's leading polymer and rubber manufacturer. Its products find application in a variety of industries across the globe, including agriculture, construction, food, pharmaceutical, healthcare and automotive industries. Total revenue in 2021: around $10bln (estimated)",
  },
  {
    pictureUrl: NikitaVorobyev,
    name: "Nikita Vorobyev",
    designation: "Head of climate and ecology, NLMK",
    details:
      "NLMK Group is a leading international manufacturer of high-quality steel products with a vertically integrated business model. Mining and steelmaking are concentrated in cost-efficient regions; finished products are manufactured close to the main customers in Russia, North America, and the EU. Total revenue in 2021: $16.2 bln",
  },
  {
    pictureUrl: EvgeniyKhilinskiy,
    name: "Evgeniy Khilinskiy",
    designation: "Head of Sustainable Development Hub, Gazprombank JSC",
    details:
      "Gazprombank (Joint Stock Company) is one of the largest multi-faceted financial institutions in Russia, providing a wide range of banking, financial, investment products and services to corporate and private customers, financial institutions, institutional and private investors. It is one of the three largest banks in Russia by all major indicators and ranks third in the list of banks in Central and Eastern Europe in terms of equity.",
  },
  {
    pictureUrl: OlgaKalashnikova,
    name: "Olga Kalashnikova",
    designation: "Head of Environment, Severstal",
    details:
      "Russian vertically integrated steel and mining company. Severstal remains a global leader in efficiency, with the highest EBITDA margin in the steel industry worldwide, positive free cash flow generation throughout the cycle and a track record of achieving its targets. Total revenue in 2021: $11.6 bln",
  },
  {
    pictureUrl: IrinaBahtina,
    name: "Irina Bahtina",
    designation: "Director for Sustainable Development, RUSAL",
    details:
      "RUSAL is a leading company in the global aluminium industry, producing metal with a low carbon footprint. 90% of the Company's aluminium is produced from renewable electricity, and by implementing innovative and energy-saving technologies RUSAL is able to reduce greenhouse gas emissions at all production stages. This has enabled RUSAL to become one of the first in the world to master the production of 'green' metal under the ALLOW brand. Total revenue in 2021: $12 bln",
  },
  {
    pictureUrl: ArindamLahiri,
    name: "Mr. Arindam Lahiri",
    designation:
      "Chief Executive Officer, Automotive Skills, Development Council - ASDC",
    details:
      "A passionate professional with career spanning 3 decades; the initial decade was spent in manufacturing sector, followed by 2 decades of experience across all domains of education & training. He started his career with Maruti Udyog, received global exposure at Honeywell Inc. (USA) and had an entrepreneurial stint as Director & Co-founder at Career Launcher India. His last assignment was with education division of HT Media Ltd.",
  },
  {
    pictureUrl: KristinaPopilyuk,
    name: "Kristina Popilyuk",
    designation: "Head of the Department of Sustainable Development, Novatek",
    details:
      "NOVATEK is the largest independent natural gas producer in Russia. The Company is principally engaged in the exploration, production, processing and marketing of natural gas and liquid hydrocarbons and have more than 20 years of operational experience in the Russian oil and natural gas sector. Total revenue in 2021: $15.7 bln",
  },
  {
    pictureUrl: AnjalPrakash,
    name: "Dr Anjal Prakash",
    designation:
      "Associate Professor Research & Research Director, Bharti Institute of Public Policy, ISB ",
    details:
      "Dr Anjal Prakash is an experienced researcher and academician, currently serving as the Research Director at the Bharti Institute of Public Policy, Indian School of Business.",
  },

  {
    pictureUrl: DineshAgrawal,
    name: "Mr. Dinesh Agrawal",
    designation: "Principal Consultant with Consocia Advisory",
    details:
      "Mr. Dinesh Agrawal is working as a Principal Consultant with Consocia Advisory and has more than 40 years of experience in the areas of ESG, Sustainability, Business Excellence, Corporate Social Responsibility, Resettlement & Rehabilitation and land acquisition etc. ",
  },
  {
    pictureUrl: SanjayGupta,
    name: "Mr. Sanjay Gupta",
    designation:
      "Former President (2017-18) of The Institute of Cost Accountants of India",
    details:
      "He was Nominated by Hon’ble Union Minister of Science & Technology for RGCB for Finance Committee (2021-23),  Nominated by Hon’ble Union Minister of Health and Family Welfare for National Institute of Immunology for Finance Committee (2023-25). ",
  },
  {
    pictureUrl: SuchinJain,
    name: "Commander Suchin Jain (Retd)",
    designation: "Founder director and CEO of iPanelKlean",
    details:
      "Commander Suchin Jain (Retd) is a naval architect, structural designer and analyst for indigenous aircraft carrier INS Vikrant and worked in Russia at Severodvinsk for over 2 years for modernisation of aircraft carrier Adm Gorshkov, rechristened INS Vikramaditya",
  },
]
